<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '90vw', maxWidth: '600px' }"
    header="Formulário de Usuários"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="field col-12" v-if="!user.id">
      <label for="matricula">Matrícula / CPF</label>
      <div class="p-inputgroup">
        <InputText
          id="matricula"
          v-model="credential"
          :disabled="user.person.id"
          placeholder="Informe a matrícula ou o CPF."
          @keyup.enter="getPerson"
        />
        <Button
          v-if="!user.person.id"
          icon="pi pi-search"
          @click="getPerson"
          class="p-button"
        />
        <Button
          v-else
          icon="pi pi-times"
          @click="resetForm"
          class="p-button p-button-danger"
        />
      </div>
    </div>

    <!-- Exibir informações da pessoa encontrada -->
    <Chip
      :label="user.person.name + ' - ' + user.person.cpf"
      v-if="user.person.id"
    />

    <!-- Exibe os campos apenas se a pessoa for encontrada -->
    <div class="grid justify-content-center" v-if="user.person.id || user.id">
      <div class="field col-12">
        <label for="email">E-mail</label>
        <InputText
          id="email"
          v-model="v$.user.email.$model"
          maxlength="255"
          placeholder="Digite o e-mail"
          :class="{ 'p-invalid': submitted && v$.user.email.$invalid }"
        />
        <small v-if="submitted && v$.user.email.email.$invalid" class="p-error"
          >Email inválido</small
        >
        <small class="p-error" v-else-if="submitted && v$.user.email.$invalid"
          >Email é obrigatório.</small
        >
      </div>

      <div class="field col-12 md:col-6" v-if="!user.id">
        <label for="password">Senha</label>
        <Password
          id="password"
          v-model="v$.user.password.$model"
          placeholder="Digite sua senha"
          :class="{ 'p-invalid': submitted && !v$.user.password.$model }"
        />
        <small v-if="submitted && !v$.user.password.$model" class="p-error"
          >Senha é obrigatório.</small
        >
        <small
          v-else-if="submitted && v$.user.password.minLength.$invalid"
          class="p-error"
          >A senha deve ter no mínimo 6 caracteres</small
        >
      </div>

      <div class="field col-12 md:col-6" v-if="!user.id">
        <label for="password">Confirmar senha</label>
        <Password
          id="password"
          v-model="confirmPassword"
          placeholder="Confirme sua senha"
          :class="{
            'p-invalid': submitted && confirmPassword !== user.password,
          }"
        />
        <small
          class="p-error"
          v-if="submitted && confirmPassword !== user.password"
          >As senhas devem ser iguais</small
        >
      </div>

      <div class="field col-12">
        <label for="group">Grupos</label>
        <MultiSelect
          id="group"
          v-model="v$.user.group.$model"
          :options="groups"
          :filter="true"
          optionLabel="description"
          placeholder="Selecione um grupo"
          :class="{
            'p-invalid': submitted && v$.user.group.$invalid,
          }"
        />
        <small class="p-error" v-if="submitted && v$.user.group.$invalid"
          >Grupo é obrigatório.</small
        >
      </div>
    </div>

    <template #footer>
      <Button
        v-if="user.person.id"
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.user.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>

<script>
import Users from "../../../models/users";
import UsersService from "../../../service/user/user_service";
import GroupService from "../../../service/group/group_service";
import PersonService from "../../../service/persons/person_service";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["userSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      user: new Users(),
      submitted: false,
      confirmPassword: null,
      credential: "",
      previousCredential: "", // Propriedade para armazenar o valor anterior
      userService: new UsersService(),
      groupService: new GroupService(),
      personService: new PersonService(),
      groups: [],
    };
  },
  validations() {
    return {
      user: new Users().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.users.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.users.dialogForm = value;
      },
    },
  },
  watch: {
    credential(newValue) {
      // Se o user.person.id já estiver definido e o credential mudar, resetar o formulário
      if (this.user.person.id && newValue !== this.previousCredential) {
        this.resetForm();
      }
    },
  },
  methods: {
    send(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        if (this.user.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.userService
        .create(this.user)
        .then((data) => {
          if (data.status === 201) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro cadastrado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      console.log(this.user)
      this.userService
        .update(this.user)
        .then((data) => {
          if (data.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro alterado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.user = new Users();
      this.confirmPassword = null;
      this.submitted = false;
      this.credential = ""; // Limpa a matrícula ao fechar
      this.previousCredential = null; // Limpa o valor anterior ao fechar
      this.visibleDialog = false;
    },
    getData() {
      if (this.userSelected.id) {
        this.user = this.userSelected;
        this.user.password = "12345678910"; //manda um password padrão apenas por obrigatoriedade da API, o mesmo não é salvo.
      }
      this.findGroups();
    },
    findGroups() {
      this.groupService.findAll().then((data) => {
        this.groups = data;
        /*
        // Filtra os grupos com defaultGroup = true e extrai os IDs
        this.user.group = this.groups
          .filter((group) => group.defaultGroup)
          .map((group) => group);*/
      });
    },
    getPerson() {
      this.personService
        .findByCpfOrCredential(this.credential)
        .then((res) => {
          this.user.person = res;
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    resetForm() {
      this.user = new Users();
      this.confirmPassword = null;
      this.submitted = false;
      this.credential = ""; // Limpa a matrícula
      this.previousCredential = null; // Limpa o valor anterior
    },
  },
};
</script>

<style scoped>
</style>
