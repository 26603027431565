<template>
  <Panel header="Gerenciamento de Usuários" style="height: 100%">
    <Fieldset legend="Filtrar por" class="mb-3">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="nome">Nome</label>
          <InputText
            id="nome"
            v-model="lazyParams.person"
            autofocus
            placeholder="Digite o nome do usuário"
            @keydown.enter="loadLazyData"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label for="nome">Matricula</label>
          <InputText
            id="nome"
            v-model="lazyParams.login"
            autofocus
            placeholder="Digite a matricula do usuário"
            @keydown.enter="loadLazyData"
          />
        </div>
        <div class="field col-12 md:col-4">
            <label for="cpf">CPF</label>
            <InputMask
              id="cpf"
              v-model="lazyParams.cpf"
              mask="999.999.999-99"
              autofocus
              placeholder="Digite o CPF"
              @keydown.enter="loadLazyData"
            />
          </div>
        <div class="field col-12 md:col-4">
          <label for="nome">E-mail</label>
          <InputText
            id="nome"
            v-model="lazyParams.email"
            autofocus
            placeholder="Digite o e-mail"
            @keydown.enter="loadLazyData"
          />
        </div>

        <div class="field col-12 md:col-4">
          <label for="acting">Atuação:</label>
          <AutoComplete
            v-model="actingInput"
            :suggestions="sectors"
            autofocus
            @complete="searchSectors"
            @keydown.enter="loadLazyData"
            :virtualScrollerOptions="{ itemSize: 38 }"
            optionLabel="name"
            placeholder="Digite nome ou sigla do setor"
            dropdown
          />
        </div>
        <div class="field col-12 md:col-4">
          <label for="capacity">Lotação:</label>
          <AutoComplete
            v-model="capacityInput"
            :suggestions="sectors"
            autofocus
            @complete="searchSectors"
            @keydown.enter="loadLazyData"
            :virtualScrollerOptions="{ itemSize: 38 }"
            optionLabel="name"
            placeholder="Digite nome ou sigla setor"
            dropdown
          />
        </div>
      </div>

      <div class="flex justify-content-end flex-wrap">
        <Button
          label="Pesquisar"
          class="p-button-raised mr-2"
          icon="pi pi-search"
          @click="loadLazyData"
        />
        <Button
          label="Limpar"
          icon="pi pi-times"
          class="p-button-raised p-button-text"
          @click="cleanAll()"
        />
      </div>
    </Fieldset>

    <Toolbar class="mb-3">
      <template #start>
        <Button
          label="Novo"
          icon="pi pi-plus"
          class="mr-2"
          @click="showCreate"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      dataKey="id"
      class="p-datatable-sm"
      :value="users"
      :lazy="true"
      :paginator="true"
      :rows="10"
      :rowsPerPageOptions="[10, 20, 50]"
      :totalRecords="totalRecords"
      :loading="loading"
      @page="onPage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Exibindo de {first} a {last} no total de {totalRecords} usuários."
    >
      <template #empty>
        <div class="text-center">Nenhum resultado encontrado...</div>
      </template>

      <Column field="login" header="Login">
        <template #body="slotProps">
          {{ slotProps.data.login }}
        </template>
      </Column>
      <Column field="person" header="Nome">
        <template #body="slotProps">
          {{ slotProps.data.person }}
        </template></Column
      >
      <Column field="email" header="E-mail">
        <template #body="slotProps">
          {{ slotProps.data.email }}
        </template></Column
      >
      <Column field="acting" header="Atuação">
        <template #body="slotProps">
          {{ slotProps.data.acting }}
        </template></Column
      >
      <Column field="capacity" header="Lotação">
        <template #body="slotProps">
          {{ slotProps.data.capacity }}
        </template></Column
      >
      <Column field="local" header="Local">
        <template #body="slotProps">
          {{ slotProps.data.local }}
        </template></Column
      >
      <Column field="status" header="Status">
        <template #body="slotProps">
          <span
            v-if="slotProps.data.status === true"
            :class="'user-badge status-' + slotProps.data.status"
            @click="changeStatus(slotProps.data)"
            v-tooltip.top="'Clique para INATIVAR'"
            style="cursor: pointer"
            >Ativo</span
          >
          <span
            v-if="slotProps.data.status === false"
            :class="'user-badge status-' + slotProps.data.status"
            @click="changeStatus(slotProps.data)"
            v-tooltip.top="'CLIQUE ATIVAR'"
            style="cursor: pointer"
            >Inativo</span
          >
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success mr-2"
            @click="showUpdate(slotProps.data)"
            v-tooltip.top="'CLIQUE PARA EDITAR'"
          />
          <Button
            icon="pi pi-lock"
            class="p-button-rounded p-button-info mr-2"
            @click="showResetPassword(slotProps.data)"
            v-tooltip.top="'CLIQUE PARA RESETAR A SENHA'"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="showRemove(slotProps.data)"
            v-tooltip.top="'CLIQUE PARA EXCLUIR'"
          />
        </template>
      </Column>
    </DataTable>
  </Panel>
  <dialog-form :userSelected="user" @findAll="loadLazyData" />
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
//Models
import Users from "../../models/users";

//Services
import UsersService from "../../service/user/user_service";
import SectorService from "../../service/sector/sector";

//Components
import DialogForm from "./components/dialog-form.vue";

export default {
  components: {
    DialogForm,
  },
  data() {
    return {
      userService: new UsersService(),
      sectorService: new SectorService(),
      user: new Users(),
      loading: false,
      totalRecords: 0,
      users: null,
      sectors: null,
      actingInput: null,
      capacityInput: null,
      lazyParams: {},
      sectorParams: {},
    };
  },
  mounted() {
    this.loading = true;

    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      login: "",
      email: "",
      person: "",
      cpf: "",
      acting: "",
      capacity: "",
      localIds: null,
    };

    this.actingInput = "";
    this.capacityInput = "";
    this.loadLazyData();
  },
  methods: {
    showCreate() {
      this.user = new Users();
      this.$store.state.views.users.dialogForm = true;
    },
    showUpdate(user) {
      this.userService.findById(user.id).then((data) => {
        this.user = data;
        this.$store.state.views.users.dialogForm = true;
      });
    },
    showResetPassword(user) {
      this.user = user;
      this.$confirm.require({
        header: "Deseja resetar a senha?",
        message: "Deseja resetar a senha do usuário?",
        icon: "pi pi-lock",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.userService
            .resetPassword(this.user)
            .then((data) => {
              this.$msgSuccess(data);
              this.loadLazyData();
            })
            .catch((error) => {
              this.$msgErro(error);
            });
        },
      });
    },
    showRemove(user) {
      this.$confirm.require({
        message: "Deseja Excluir esse registro?",
        header: "Deseja deletar?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.userService
            .delete(user.id)
            .then((data) => {
              this.$msgSuccess(data);
              this.loadLazyData();
            })
            .catch((error) => {
              this.$msgErro(error);
              //console.error(error);
            });
        },
      });
    },
    changeStatus(user) {
      this.user = user;
      this.user.status = !this.user.status;
      this.userService
        .updateStatus(user)
        .then((data) => {
          this.$msgSuccess(data);
          this.loadLazyData();
        })
        .catch((error) => {
          this.$msgErro(error);
          //console.error(error);
        });
    },
    loadLazyData() {
      this.loading = true;
      this.lazyParams.localIds = parseInt(sessionStorage.getItem("local"));

      this.lazyParams.acting =
        this.actingInput && this.actingInput.name ? this.actingInput.name : "";
      this.lazyParams.capacity =
        this.capacityInput && this.capacityInput.name
          ? this.capacityInput.name
          : "";

      this.userService.searching(this.lazyParams).then((data) => {
        this.users = data.content;
        this.totalRecords = data.totalElements;
        this.loading = false;
        if (this.totalRecords === 0) {
          this.$toast.add({
            severity: "warn",
            summary: "Alerta!",
            detail: "Não foram encontrados registros.",
            life: 3000,
          });
        }
      });
    },
    onPage(event) {
      this.lazyParams.first = event.originalEvent.first;
      this.lazyParams.page = event.originalEvent.page;
      this.lazyParams.size = event.rows;
      this.loadLazyData();
    },
    cleanAll() {
      this.lazyParams = {
        first: 0,
        size: this.$refs.dt.rows,
        sortField: null,
        sortOrder: null,
        login: "",
        person: "",
        cpf: "",
        email: "",
        acting: "",
        capacity: "",
      };
      this.actingInput = null;
      this.capacityInput = null;
      this.loading = false;
      this.loadLazyData();
    },
    searchSectors(event) {
      this.sectorParams = {
        name: event.query,
        size: 100,
        localIds: sessionStorage.getItem("local"),
      };
      this.sectorService.findAll(this.sectorParams).then((data) => {
        if (data.content.length === 0) {
          this.sectorParams = {
            acronym: event.query,
            size: 100,
            localIds: sessionStorage.getItem("local"),
          };
          this.sectorService.findAll(this.sectorParams).then((data2) => {
            this.sectors = data2.content;
          });
        } else {
          this.sectors = data.content;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../../App.scss");
</style>
